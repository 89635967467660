import { createStore } from 'vuex'
import axios from 'axios'
//import router from 'vue-router';
import router from '../router'

//import { decodeHtml, removeReturn, escapeHTML, convertQuote } from '../assets/js/customJS.js'

export default createStore({
  state: {
    params: null,
    paramsOk: false,
    categoriesList: [],
    postsList: [],
    postsLoaded: false,
    menuJSON: [],
    frati: null,
    fratiOk: false,
    docs: [],
    docsLoaded: false,
    docsJSON: {},
    docsParams: null,
    docsId: null,
    docsOk: false,
    //rootUrl: "https://ofmconv.at/zzz",
    rootUrl: "https://ofmconv.at",
    rootDatabase: "https://ofmconv.at",
    passError: false,
    nameField: "",
    passField: "",
    loginResult: "",
    loginIsActive: false,
    loginCallback: "",
    imgUrl: "/wordpress/wp-content/uploads/images/bruder/",
    adr: "",
  },
  mutations: {
    SET_PARAMS( state, val ) { state.params = val; },
    SET_PARAMS_OK( state, val ) { state.paramsOk = val; },

    SET_CATEGORIES_LIST( state, val ) { state.categoriesList = val; },
    SET_POSTS_LIST( state, val ) { state.postsList = val; },
    SET_POSTS_LOADED( state, val ) { state.postsLoaded = val; },

    SET_FRATI( state, val ) { state.frati = val; },
    SET_FRATI_OK( state, val ) { state.fratiOk = val; },
    SET_IMG_URL( state, val ) { state.imgUrl = val; },

    SET_DOCS( state, val ) { state.docs = val; },
    SET_DOCS_ID( state, val ) { state.docsId = val; },
    SET_DOCS_TITLE( state, val ) { state.docsTitle = val; },
    SET_DOCS_JSON( state, val ) { state.docsJSON = val; },
    SET_DOCS_PARAMS( state, val ) { state.docsParams = val; },
    SET_DOCS_OK( state, val ) { state.docsOk = val; },

    SET_NAME_FIELD( state, val ) { state.nameField = val; },
    SET_PASS_FIELD( state, val ) { state.passField = val; },
    SET_PASS_ERROR( state, val ) { state.passError = val; },
    SET_LOGIN_RESULT( state, val ) { state.loginResult = val; },
    SET_LOGIN_IS_ACTIVE( state, val ) { state.loginIsActive = val; },
    SET_LOGIN_CALLBACK( state, val ) { state.loginCallback = val; },
  },
  actions: {

    getFrati({ commit, state }) {
      axios.get( state.rootUrl + '/php/getFrati.php')
        .then(response => {
          commit( 'SET_FRATI', response.data);
          commit( 'SET_FRATI_OK', true );
          console.log('i frati = ', state.frati);
        }
      )
    },

    getParams({ commit, state }) {
      console.log('eccomi');
      axios.get( state.rootUrl + '/php/getParams.php')
        .then(response => {
            commit( 'SET_PARAMS', response.data )
            commit( 'SET_PARAMS_OK', true )
            console.log( 'response.data = ', state.params )
        }
      )
    },

    getDocs({ commit, state }) {
      console.log('adresse de getDocs = ', state.rootUrl + '/wordpress/?rest_route=/wp/v2/posts/' + state.docsId )
      axios.get( state.rootDatabase + '/wordpress/?rest_route=/wp/v2/posts/' + state.docsId )
        .then(response => {
            //commit( 'SET_DOCS', response.data )
            console.log('attachment = ', response.data._links["wp:attachment"][0].href )
            console.log('getDocs = ', response.data )
            state.adr = "https://ofmconv.at/wordpress/?rest_route=/wp/v2/media&parent=" + state.docsId; 
            //state.adr = response.data._links["wp:attachment"][0].href;
            //state.adr.replace("wp-json", "?rest_route=");
            axios.get( state.adr )
              .then(response => {
                  console.log( 'second getDocs = ', response.data )
                  commit( 'SET_DOCS', response.data[0].guid.rendered )
                  commit( 'SET_DOCS_TITLE', response.data[0].title.rendered );
              }) 
        })
    },

    // GET ALL CATEGORIES AND ALL ARTICLES
    getCatPostList({ commit, state }) {
      var donneesCat = [];
      var promisesCat = [];
      var nbrPagesCat = 0;
      // Get how many pages of Categories
      axios.get( state.rootDatabase + '/wordpress/?rest_route=/wp/v2/categories&per_page=100' )
        .then(response => {
          nbrPagesCat = response.headers['x-wp-totalpages'];

          // For each pages of Categories
          for (var i = 0; i < nbrPagesCat; i++) {
            promisesCat.push(
              axios.get( state.rootDatabase + '/wordpress/?rest_route=/wp/v2/categories&per_page=100' )
                .then(response => {
                  donneesCat = donneesCat.concat(response.data)
                })
            )
          }
          Promise.all( promisesCat ).then(() => { // execute all axios promises

            var donneesPost = [];
            var promisesPost = [];
            var nbrPagesPost = 0;
            // Get how many pages of Categories
            axios.get( state.rootDatabase + '/wordpress/?rest_route=/wp/v2/posts&per_page=100')
              .then(response => {
                nbrPagesPost = response.headers['x-wp-totalpages'];
                // console.log ('post x-wp-totalpages = ', nbrPagesPost)

              for (var i = 0; i < nbrPagesPost; i++) {
                promisesPost.push(
                  axios.get( state.rootDatabase + '/wordpress/?rest_route=/wp/v2/posts&per_page=100' )
                    .then(response => {
                      donneesPost = donneesPost.concat(response.data)
                    })
                )
              }
              Promise.all( promisesPost ).then(() => {
                // sort the categories
                console.log('données avant tri = ', donneesCat)
                //console.log('posts avant tri = ', donneesPost)
                donneesCat.sort((a, b) => b.slug.localeCompare(a.slug));
                donneesPost.sort((a, b) => b.date.localeCompare(a.date));
                console.log('données après tri = ', donneesCat)
                commit( 'SET_CATEGORIES_LIST', donneesCat );
                commit( 'SET_POSTS_LIST', donneesPost );
                commit( 'SET_POSTS_LOADED', true );
                console.log( 'CATEGORIES_LIST = ', state.categoriesList )
                console.log( 'POSTS_LIST = ', state.postsList )
              })
            });
      
          });
        })
    },

    // GET ALL CATEGORIES AND ALL ARTICLES
    verifyCredentials({ commit, state }) {
      axios.get( state.rootUrl + '/php/verifyCredentials.php?name=' + state.nameField + '&pass=' + state.passField )
        .then(response => {
          //commit( 'SET_FRATI', response.data);
          console.log('response = ', response.data)
          if ( response.data != "ok" ) {
              commit( 'SET_NAME_FIELD', "" );
              commit( 'SET_PASS_FIELD', "" );   
              commit( 'SET_PASS_ERROR', true );
          }
          else {
            localStorage.setItem( "nameField", state.nameField );
            localStorage.setItem( "passField", state.passField );
            // if the login has been asked by another page go back to it
            if ( state.loginCallback == undefined ) {
              commit( 'SET_LOGIN_IS_ACTIVE', false ); } // hide login panel and show app menu
            else {
              commit( 'SET_LOGIN_CALLBACK', "" )
              router.go(-1)
            }
          }
          commit( 'SET_LOGIN_RESULT', response.data );
        }
      )    
    },

  }
})
